import { Button, CardMedia, Grid, Typography } from "@mui/material";
import MyAppBar from "../GlobalComponents/AppBar";
import fullStack from '../images/financialTracker.png'
import storedProcedures from '../images/storedProcedures.png'
import REST from '../images/REST.png'
import './website.css';

function FullStack() {

    return (
        <div>
            <MyAppBar />
            <div className="titles">
                <Typography variant="subtitle1">Full Stack Financial Tracker</Typography>
                <Typography variant="subtitle2">Development Time: 4 months</Typography>
                <Typography variant="subtitle2">Technologies: SQL, React, Node.JS, REST</Typography>
                <Typography variant="subtitle2">Group Size: 4</Typography>
                <Typography variant="subtitle2">**Note: Back-End local MySQL database is lost. Code will not run.</Typography>
                <Button target="blank" href="https://github.com/LougheedWan/CPSC471-Full-Stack-APP" variant="outlined">Click Here to See Code Base</Button>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Detailed Summary</Typography>
                <Typography variant="subtitle2">This project was done with 4 other individuals for a database management systems course. The objective
                was to build a complete full stack application that would track your spending habits. It uses a React JS front-end and a Node.JS, MySQL backend.
                Data was pushed over the web using RESTful web services. This was my first attempt in developing full stack software, and unfortunately without funding
                to make a real remote server, the database was created locally on an old computer of mine. Which at this present day is obsolete. Thus the code will not run properly
                as there is no database for Node.JS to connect to.
                </Typography>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Key Features:</Typography>
                <Grid container rowSpacing={25} columnSpacing={4} className="keyFeatures">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">RESTful Interface</Typography>
                        <Typography variant="subtitle2">A key part of this project was to send data to and from the server
                        using REST and JSON objects. The server side NODE.js code is structured to use restful interfaces
                        such as PUT, POST, and GET.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={REST}
                        ></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={storedProcedures}></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm ={6}>
                        <Typography variant="subtitle3">Stored Procedures</Typography>
                        <Typography variant="subtitle2">To safeguard against SQL injection attacks, this project uses
                        stored procedures to query into the local database. By doing so, the project does not allow malicious SQL user inputs
                        which might cause data leaks or crashes.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">React Framework with Bootstrap Front End</Typography>
                        <Typography variant="subtitle2">The front end UI design of this website is made using React and Bootstrap CSS. Using these technologies
                        I was able to create a much prettier user interface than with regular JS and CSS.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={fullStack}
                        ></CardMedia>
                    </Grid>
                </Grid>
            </div>
            
        </div>

    )
}

export default FullStack