import '../Pages/home.css';
import portrait from '../images/portrait.jpg'
import Resume from '../pdf/Resume.pdf'
import SubHeader from './subHeader';
import MyAppBar from '../GlobalComponents/AppBar';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import ArticleIcon from '@mui/icons-material/Article';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import {
  Typography,
  Button,
  Grid,
  IconButton,
  CardMedia
} from '@mui/material'

function Home() {
  return (
    <div id="Title">

      <div>
        <MyAppBar />
      </div>
      <Grid container rowSpacing={25} columnSpacing={4} className='titleScreen'>
        <Grid item xs={6}>
        <CardMedia className="image"
                        component='img'
                        src={portrait}
                        ></CardMedia>
        </Grid>
        <Grid item xs={6}>
          <div className='title' >
            <Typography variant="heading" className='anim-typewriter'>
              Lougheed Wan
            </Typography>
            <Typography variant="subheading" className='anim-typewriter2'>
              Software Developer
            </Typography>
            <div>
              <IconButton size="large" target='blank' href='https://github.com/LougheedWan'
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 1, color: '#ffffff' }}
              ><GitHubIcon /></IconButton>
              <IconButton size="large" target='blank' href='https://linkedin.com/in/lougheedwan'
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 1, color: '#ffffff' }}><LinkedInIcon /></IconButton>
                <IconButton size="large" target='blank' href= {Resume}
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 1, color: '#ffffff' }}><ArticleIcon /></IconButton>
            </div>
            <Button href='#SubHeader' variant='outlined' size='large'
              sx={{
                width: 200,
                fontSize: 'large',
                float: 'left',
                marginTop: '3px',
                fontWeight: 'lesser'
              }}
              endIcon={<ArrowDownwardIcon />}>LEARN MORE</Button>

          </div>
        </Grid>
      </Grid>

      <SubHeader />
    </div>


  );
}

export default Home;
