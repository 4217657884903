import './App.css';
import Home from './Pages/home';
import AboutMe from './Pages/AboutMe';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import Website from './Pages/website';
import CyberSecurity from './Pages/CyberSecurity';
import AI from './Pages/AI';
import FullStack from './Pages/FullStack';
import Zenio from './Pages/Zenio';
import VR from './Pages/VR';
import Networking from './Pages/Networking'
import { Routes, Route } from 'react-router-dom';

function App(){

  return(
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/aboutMe" element={<AboutMe/>} />
    <Route path="/projects" element={<Projects/>} />
    <Route path="/projects/website" element={<Website/>} />
    <Route path="/projects/CyberSecurity" element={<CyberSecurity/>} />
    <Route path="/projects/AI" element={<AI/>} />
    <Route path="/projects/FullStack" element={<FullStack/>} />
    <Route path="/projects/Networking" element={<Networking/>} />
    <Route path="/projects/Zenio" element={<Zenio/>} />
    <Route path="/projects/VR" element={<VR/>} />
    <Route path="/contact" element={<Contact/>} />
  </Routes>
  );
}

export default App