import { Typography, Grid, CardMedia } from "@mui/material";
import MyAppBar from "../GlobalComponents/AppBar";
import ReactiveVid from '../images/responsiveVid.mov'
import SprintPic from '../images/sprintPlanning.png'
import './website.css'

function Website() {

    return (
        <div>
            <MyAppBar />
            <div className="titles">
                <Typography variant="subtitle1">My Portfolio</Typography>
                <Typography variant="subtitle2">Development Time: 1.5 months</Typography>
                <Typography variant="subtitle2">Technologies: React, Material UI</Typography>
                <Typography variant="subtitle2">Group Size: Individual </Typography>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Detailed Summary</Typography>
                <Typography variant="subtitle2">The idea of this project was to create
                something that not only would demonstrate my expertise in web development
                but as well as have something to summarize my previous works and experiences.
                This website was created from scratch using the React Javascript framework, as well
                as the Material UI CSS library. Using techniques learnt in my university degree as well
                as my internship, such as Agile and sprint planning, I was able to effectively complete this
                project in a timely manner, while balancing my other employments and hobbies.
                </Typography>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Key Features:</Typography>
                <Grid container rowSpacing={25} columnSpacing={4} className="keyFeatures">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">Responsive Design:</Typography>
                        <Typography variant="subtitle2">Throughout the website, there are
                        many examples of responsiveness relative to screen size. A good example
                        of this would be the projects main page, where the cards change position relative
                        to how large the screen is. This is possible due to Material UI and the Grid component</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='video'
                        src={ReactiveVid}
                        controls
                        ></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={SprintPic}></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm ={6}>
                        <Typography variant="subtitle3">Sprint Planning</Typography>
                        <Typography variant="subtitle2">To keep organized I used Notion as
                        my main story/ticket organizer. I used the Agile methodology learnt
                        in my university classes as well as in my internship with VistaVu Solutions
                        to keep my tasks organized in bi weekly sprints. This allowed me to stay
                        on top of my core priorities with the website as well as give estimates on how long
                        the development timeline would be. Ultimatly improving overall productivity.</Typography>
                    </Grid>
                </Grid>
            </div>
            
        </div>

    )
}

export default Website