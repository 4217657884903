import { Typography, Grid, CardMedia, LinearProgress, Divider} from "@mui/material";
import MyAppBar from "../GlobalComponents/AppBar";
import myFace1 from '../images/myFace1.jpeg';
import myFace2 from '../images/MyFace2.jpeg';
import myFace3 from '../images/myFace3.jpeg'
import './AboutMe.css'

function AboutMe() {

    return (
        <div>
            <MyAppBar />
            <Grid container rowSpacing={25} columnSpacing={4} className="grid">
                <Grid item xs={12} sm={6}>
                    <div className="AboutMeTitle">
                        <Typography variant="subtitle1">Who Am I?</Typography>
                        <Typography variant="subtitle2" sx={{textAlign: 'center'}}>Well I'm glad you asked! My name is Lougheed, a software developer by day, cyclist, hiker, skiier and gamer by night. Thank you for visitng my website! It means
                        alot to me. Keep reading to find out more about who I am!</Typography>
                    </div>
                    <div className="divider">
                        <Divider color="white" className="divide"/> 
                    </div>
                    <Typography variant="subtitle1" className="AboutMeTitle">Skills</Typography>
                    <div className='AboutMeTitle'>
                        <Typography variant="subtitle2">Web Development</Typography>
                    </div>
                    <LinearProgress variant="determinate" value={90} color="success"/>
                    <div className='AboutMeTitle'>
                        <Typography variant="subtitle2">Database Queries</Typography>
                    </div>
                    <LinearProgress variant="determinate" value={70} color="success"/>
                    <div className='AboutMeTitle'>
                        <Typography variant="subtitle2">REST</Typography>
                    </div>
                    <LinearProgress variant="determinate" value={80} color="success"/>
                    <div className='AboutMeTitle'>
                        <Typography variant="subtitle2">Problem Solving</Typography>
                    </div>
                    <LinearProgress variant="determinate" value={95} color="success"/>
                    <div className='AboutMeTitle'>
                        <Typography variant="subtitle2">Critical Thinking</Typography>
                    </div>
                    <LinearProgress variant="determinate" value={85} color="success"/>
                    
                </Grid>
                <Grid item xs={12} sm={6} className="gridItem"> 
                    <CardMedia className="photo"
                        component='img'
                        src={myFace1}
                        ></CardMedia>
                </Grid>
                <Grid item xs={12} sm={6} className="gridItem"> 
                    <CardMedia className="photo"
                        component='img'
                        src={myFace2}
                        ></CardMedia>
                </Grid>
                <Grid item xs={12} sm={6} className="gridItem"> 
                    <Typography variant="subtitle1">My Story</Typography>
                    <Typography variant="subtitle2">Born and raised in Calgary, I grew up in a immigrant family, where my mother and father were originally from Hong Kong. Being an only child, I learnt
                    at an early age independence and self care. A long standing hobby of mine was video games, like many young children in the early 2000s, I was gifted a Sony Playstation 2, from then on,
                    the rest was history.</Typography>
                    <div className="divider">
                        <Divider  color="white" className="divide"/> 
                    </div>
                    <Typography variant="subtitle2">Growing up, I always found a joy in the technological world. Greatly steming by my love of video games, I grew to enjoy learning about the new technologcial trends
                    around the world. By the time junior high came around, I began wandering down the path of software development, with my first baby steps into coding originating from things such as Lego Mindstorms, Scratch and Arduino kits </Typography>
                    <div className="divider">
                        <Divider  color="white" className="divide"/> 
                    </div>
                    <Typography variant="subtitle2">Continuing on with my studies, I continued to dive deeper and deeper into the world of computer science, entranced by the immense amount of creativity and power that you have in this field. By
                    high school I enrolled in a computer science elective, this was my first encounter with "real" coding learning the basics in Java. Throughout my 3 years in high school, I continued to take the computer science stream of electives,
                    ultimately leading to my major choice in university</Typography>
                    <div className="divider">
                        <Divider  color="white" className="divide"/> 
                    </div>
                    <Typography variant="subtitle2">As my adulthood approached, I decided to continue my studies in this magical field of computer science and enrolled in the computer science undergraduate degree at the University of Calgary.
                    After 4 years of studies and 1 full year as an intern software developer, I graduated with my Batchelor of Science in November of 2023. </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="gridItem">
                    <Typography variant="subtitle1">What Else?</Typography>
                    <Typography variant="subtitle2">Outside from my love of computer science and coding, I am an avid outdoor enthusiast. I find that after being couped up at the desk all day in front of a monitor,
                    there is no better way to relax than to head into the outdoors for some fresh air. </Typography>
                    <div className="divider">
                        <Divider color="white" className="divide"/> 
                    </div>
                    <Typography variant="subtitle2">Currently my favorite hobbies consist of road cycling, hiking and skiing. More often than not, you can find me out in the mountains all year long either shredding some fresh pow
                    or getting my boots dirty on a popular trail. If I am not out of the city, you can catch me on quiet sub urban roads working up a sweat on my road bike.</Typography>
                    <div className="divider">
                        <Divider  color="white" className="divide"/> 
                    </div>
                    <Typography variant="subtitle2">My passion to strive for the best bleeds into everything that I do. Wtih my positive attitude and outgoing nature, I tend to be able to make friends quite easily. However, with a more
                    introverted nature, I also excel at keeping myself company and doing things by myself. </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="gridItem">
                    <CardMedia className="photo"
                            component='img'
                            src={myFace3}
                            ></CardMedia>
                </Grid>
               
            </Grid>
            
            
        </div>

    )
}

export default AboutMe