import { Button, CardMedia, Grid, Typography } from "@mui/material";
import MyAppBar from "../GlobalComponents/AppBar";
import finalProduct from '../images/ARFinalProduct.png'
import brickBreaker from '../images/brickBreaker.png'
import objectTracking from '../images/objectTracking.png'
import paper from '../pdf/ARBrickBreaker.pdf'
import './website.css';

function VR() {

    return (
        <div>
            <MyAppBar />
            <div className="titles">
                <Typography variant="subtitle1">AR Brick Breaker Research/Coding Project</Typography>
                <Typography variant="subtitle2">Development Time: 4 months</Typography>
                <Typography variant="subtitle2">Technologies: Unity, C#</Typography>
                <Typography variant="subtitle2">Group Size: 4</Typography>
                <Button target="blank" href={paper} variant="outlined">Click Here to See the Research Paper</Button>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Detailed Summary</Typography>
                <Typography variant="subtitle2">This project was done with 3 other individuals for a introductory VR/AR senior year class. Each group was tasked with
                researching/developing a VR/AR application of their own choosing. Our group decided on developing an AR twist on a classic video game: Brick Breaker. Using
                real life objects as a tracker for the player brick, we were able to incorporate AR components into this infamous game of the 90s. 
                </Typography>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Key Features:</Typography>
                <Grid container rowSpacing={25} columnSpacing={4} className="keyFeatures">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">AR object Tracking</Typography>
                        <Typography variant="subtitle2">The project was split into 2 separate sections, the AR side and the game logic side. For the AR side, I first developed a prototype
                        which was able to use any regular smartphone camera to detect a certain object. Once the object was detected, the script would project a virtual instance ontop of it, and as well as track its movement.
                        Thus ultimately would become the player block in the final implementation.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={objectTracking}
                        ></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={brickBreaker}></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm ={6}>
                        <Typography variant="subtitle3">Game Logic</Typography>
                        <Typography variant="subtitle2">For the game logic side of the project, we developed our own brick breaker game in Unity without any AR/VR components first. Thus, we had all the game physics and logic
                        finished and would just need to change the components to fit the AR prototype that I created.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">Closing the Loop</Typography>
                        <Typography variant="subtitle2">The final step in the development process was to close the loop by implementing both our game logic and AR logic together. By changing
                        the components of the game logic into AR components, we were able to create a AR Brick Breaker game using real life objects as the player controller.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={finalProduct}
                        ></CardMedia>
                    </Grid>
                </Grid>
            </div>
            
        </div>

    )
}

export default VR