import { Typography, Grid, CardMedia, Button } from "@mui/material";
import MyAppBar from "../GlobalComponents/AppBar";
import AllTests from '../images/allTests.png'
import CaseStudy from '../images/CaseStudy.png'
import paper from '../pdf/Password_Security.pdf'
import './website.css'

function CyberSecuity() {

    return (
        <div>
            <MyAppBar />
            <div className="titles">
                <Typography variant="subtitle1">Password Security on Critical Sectors Research Paper</Typography>
                <Typography variant="subtitle2">Development Time: 4 months</Typography>
                <Typography variant="subtitle2">Technologies: HashCat </Typography>
                <Typography variant="subtitle2">Group Size: 4 </Typography>
                <Button target="blank" href={paper} variant="outlined">Click Here to Read Full Paper</Button>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Detailed Summary</Typography>
                <Typography variant="subtitle2">During my final year in university, for a cyber security
                class, myself and 3 other individuals were tasked with performing some research on a critical
                topic in the cyber security space. We finalized on the idea of analyzing password strengths in critical sectors
                focusing on the true strength of minimum password requirements that a company is requesting. Using HashCat, a
                brute force open source password cracker, we found that on today's most cutting edge hardware, most password requirements
                from companies are not adequate in providing a "good" amount of security to its clients.
                </Typography>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Key Findings:</Typography>
                <Grid container rowSpacing={25} columnSpacing={4} className="keyFeatures">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">Minimum Password Strength is 10 plus Characters:</Typography>
                        <Typography variant="subtitle2">After using HashCat to estimate how long it will take to crack
                        a plethora of different password requirements, a key finding was that passwords are not adequately "good" (meaning taking more than 24
                        hours to crack) untill it is at least 10 characters long. This was shocking as the majority of passwords
                        that people use today are much less characters.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={AllTests}
                        ></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={CaseStudy}></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm ={6}>
                        <Typography variant="subtitle3">Critical Sectors are Not Strict Enough</Typography>
                        <Typography variant="subtitle2">Another conclusion we came to at the end of our research 
                        was that the critical sector websites we took a look at (such as Facebook or CIBC) did not have a strong
                        enough minimum password requirement, resulting in extremely quick and concerning crack times. A recommendation
                        that resulted of this was to ensure all your passwords are more than 10 charcters long, and as well as diverse in both
                        symbols, characters and numbers</Typography>
                    </Grid>
                </Grid>
            </div>
            
        </div>

    )
}

export default CyberSecuity