import { Typography, Grid, Card, CardContent, CardMedia, Button } from "@mui/material";
import MyAppBar from "../GlobalComponents/AppBar";
import portfolioCard from '../images/portfolio.png'
import passwordCard from '../images/password.jpg'
import financialTracker from'../images/financialTracker.png'
import Zenio from '../images/Zenio.png'
import AICard from '../images/AI.jpg'
import NetworkingCard from '../images/networking.jpg'
import ARcade from '../images/ARcade.png'
import { useNavigate } from 'react-router-dom'
import './Projects.css'

function Projects() {
    const navigate = useNavigate();
    return (
        <div>
            <MyAppBar />
            <div className = 'ProjectTitle'>
            <Typography variant="subtitle1">Projects</Typography>
            <Typography variant="subtitle2">This page consists of a summary of all the projects I have
            done in the past, to learn more about each project, click on the "learn more" button.</Typography>
            </div>
                 <Grid container spacing= {7} className="projectsBox">
                    <Grid item xs={12} sm={6} md={4}>
                    <Card className='projectsCard'>
                        <CardContent>
                            <Typography variant="cardtitle1">Portfolio Website</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height= 'auto'
                                image={portfolioCard}
                                alt=""

                            />
                            <Typography variant="cardDescription">The website you are on right now! Utilizing techniques learnt in my previous employments,
                            this website was developed using React and MUI CSS. </Typography>
                            <Button onClick={() => navigate('/projects/website')} variant='text' size='small' sx={{marginTop: '10px', display: 'block'}}>Learn More</Button>
                        </CardContent>
                    </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <Card className='projectsCard'>
                        <CardContent>
                            <Typography variant="cardtitle1">Cyber Security Password Strengths</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height= 'auto'
                                image={passwordCard}
                                alt=""

                            />
                            <Typography variant="cardDescription">A research project done with 3 other individuals, analyzing modern password
                            cracking technologies and the effectiveness of password requirements in critical sectors. </Typography>
                            <Button onClick={() => navigate('/projects/CyberSecurity')} variant='text' size='small' sx={{marginTop: '10px', display: 'block'}}>Learn More</Button>
                        </CardContent>
                    </Card>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={4}>
                    <Card className='projectsCard'>
                        <CardContent>
                            <Typography variant="cardtitle1">Genetic Algorithm Scheduler</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height= 'auto'
                                image={AICard}
                                alt=""

                            />
                            <Typography variant="cardDescription">A coding project done with 3 other individuals, building from scratch a python based
                            genetic algorithm to solve a complex scheduling problem </Typography>
                            <Button onClick={() => navigate('/projects/AI')} variant='text' size='small' sx={{marginTop: '10px', display: 'block'}}>Learn More</Button>
                        </CardContent>
                    </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <Card className='projectsCard'>
                        <CardContent>
                            <Typography variant="cardtitle1">UDP File Transfer Simulation</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height= 'auto'
                                image={NetworkingCard}
                                alt=""

                            />
                            <Typography variant="cardDescription">An individual coding project focusing on using 
                            the UDP networking protocol to perform file transfers of any kind and any size. Simulating
                            what would happen between a client and server enviroment in the webspace. </Typography>
                            <Button onClick={() => navigate('/projects/Networking')} variant='text' size='small' sx={{marginTop: '10px', display: 'block'}}>Learn More</Button>
                        </CardContent>
                    </Card>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={4}>
                    <Card className='projectsCard'>
                        <CardContent>
                            <Typography variant="cardtitle1">Financial Tracker Full Stack Application</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height= 'auto'
                                image={financialTracker}
                                alt=""

                            />
                            <Typography variant="cardDescription">A coding project done with 2 other individuals developing a full stack
                            website using React, Node.JS and SQL. Designed both front and back end from scratch. </Typography>
                            <Button onClick={() => navigate('/projects/FullStack')} variant='text' size='small' sx={{marginTop: '10px', display: 'block'}}>Learn More</Button>
                        </CardContent>
                    </Card>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={4}>
                    <Card className='projectsCard'>
                        <CardContent>
                            <Typography variant="cardtitle1">Zenio (Mood Tracker for Students)</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height= 'auto'
                                image={Zenio}
                                alt=""

                            />
                            <Typography variant="cardDescription">A mobile mood tracker application developed in a team of 4
                            for the 2021 hackTheChange hackathon. Developed this React based hi-fi prototype in 48 hours. </Typography>
                            <Button onClick={() => navigate('/projects/Zenio')} variant='text' size='small' sx={{marginTop: '10px', display: 'block'}}>Learn More</Button>
                        </CardContent>
                    </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <Card className='projectsCard'>
                        <CardContent>
                            <Typography variant="cardtitle1">VR Brick Breaker</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height= 'auto'
                                image={ARcade}
                                alt=""

                            />
                            <Typography variant="cardDescription">A coding/research project done with 3 other individuals developing
                            a VR brick breaker game prototype. Developed using Unity and C# </Typography>
                            <Button onClick={() => navigate('/projects/VR')} variant='text' size='small' sx={{marginTop: '10px', display: 'block'}}>Learn More</Button>
                        </CardContent>
                    </Card>
                    </Grid>
                 </Grid>
        </div>

    )
}

export default Projects