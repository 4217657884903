import '../Pages/home.css';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  MenuItem
} from '@mui/material'

import { useState } from 'react';

function MyAppBar() {

    const data = [
        { name: "Home", icon: <HomeIcon />, route: "/"},
        { name: "About Me", icon: <InfoIcon />, route: "/aboutMe"},
        { name: "Projects", icon: <WorkIcon />, route: "/projects" },
    
      ];

    //define a state open, and a function setOpen, then set the open state to false
    const [open, setOpen] = useState(false);

    function handleDrawerClick() {
        //open drawer
        setOpen(true);
    }


  const navigate = useNavigate();

    return (
        <div>
            <AppBar position='relative' style={{ background: 'transparent', boxShadow: 'none', color: '#FFFFFF' }}>
                <Toolbar>
                    <Typography variant='h5' fontWeight={'bold'} component="div" sx={{mr: 2, cursor: 'pointer'}}>
                        Lougheed Wan
                    </Typography>
                    {data.map((name) => (
                        <MenuItem key={name.name}>
                            <Typography onClick={() => navigate(name.route)} textAlign="center">{name.name}</Typography>
                        </MenuItem>
                    ))}
                </Toolbar>
            </AppBar>
        </div>

    );
}

export default MyAppBar