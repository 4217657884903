import { Typography, Grid, CardMedia, Button } from "@mui/material";
import MyAppBar from "../GlobalComponents/AppBar";
import AllTests from '../images/allTests.png'
import ExtensionRule from '../images/extensionRule.png'
import FinalAnswer from '../images/finalAnswer.png'
import CaseStudy from '../images/CaseStudy.png'
import Generation0 from '../images/generation0.png'
import paper from '../pdf/GeneticAlgorithm.pdf'
import projectProblem from '../pdf/ProjectProblem.pdf'
import './website.css'

function AI() {

    return (
        <div>
            <MyAppBar />
            <div className="titles">
                <Typography variant="subtitle1">Genetic Algorithm Scheduler</Typography>
                <Typography variant="subtitle2">Development Time: 4 months</Typography>
                <Typography variant="subtitle2">Technologies: Python </Typography>
                <Typography variant="subtitle2">Group Size: 4</Typography>
                <Button target="blank" href="https://github.com/LougheedWan/CPSC433-AI-Final" variant="outlined">Click Here to See Code Base</Button>
                <Button target="blank" href={projectProblem} variant="outlined">Click Here to see the Problem Description (Property of University of Calgary 2022)</Button>
                <Button target="blank" href={paper} variant="outlined">Click Here to see the Proposal Paper</Button>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Detailed Summary</Typography>
                <Typography variant="subtitle2">This project was done with 3 other individuals for an advanced AI course in my final
                year of university, it uses a genetic algorithm (basis of many AI implementations today) to find the best solution to a
                soccer team scheduling problem. It will either find a perfect solution, a best in case solution or terminate with no solution
                if the inputs do not pass the hard constraints.
                </Typography>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Key Features:</Typography>
                <Grid container rowSpacing={25} columnSpacing={4} className="keyFeatures">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">Initial Generation + Hard Constraints</Typography>
                        <Typography variant="subtitle2">The first challege in the algorithm is to generate a "valid" schedule to start
                        building upon in later generations. The program must first check through a plethora of hard constraints (detailed in the problem description)
                        in order to determine if any generated schedule is valid. Once a valid schedule (passes all hard constrants) is generated, the algorithm is free to move
                        to the next step.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={Generation0}
                        ></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={ExtensionRule}></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm ={6}>
                        <Typography variant="subtitle3">Extension Rules</Typography>
                        <Typography variant="subtitle2">After initial checks are over, the algorithm chooses 1 of 2 rules to apply: Mutation or Crossover (the other 2 rules are not used for
                        generating a new generation. [more detailed explaination in proposal paper]). These 2 rules 
                        both change the initial schedule by modifying it via its rule set. In turn, a new schedule is then created. (our next generation)</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">Eval and Recheck for Validity</Typography>
                        <Typography variant="subtitle2">The final part of this algorithm consists of an evaluation huristic calculation and a recheck for validity.
                        A perfect solution will consist of a eval score of 0, if a perfect solution is not found, the lowest eval score in a certain time limit is used as the 
                        best case solution. After every new generation creation, the program will once again check for hard constraints to determine validity, and give an eval score.
                        If the time limit is up or a perfect solution is found, then the program terminates. </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={FinalAnswer}
                        ></CardMedia>
                    </Grid>
                </Grid>
            </div>
            
        </div>

    )
}

export default AI