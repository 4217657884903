import { Button, CardMedia, Grid, Typography } from "@mui/material";
import MyAppBar from "../GlobalComponents/AppBar";
import tightDeadline from '../images/tightDeadline.png'
import login from '../images/zenioLogin.png'
import './website.css';

function Zenio() {

    return (
        <div>
            <MyAppBar />
            <div className="titles">
                <Typography variant="subtitle1">Zenio, A Mood Tracker for Students Using React Native</Typography>
                <Typography variant="subtitle2">Development Time: 48 hours</Typography>
                <Typography variant="subtitle2">Technologies: React Native</Typography>
                <Typography variant="subtitle2">Group Size: 4</Typography>
                <Button target="blank" href="https://github.com/LougheedWan/hackTheChange2021" variant="outlined">Click Here to See Code Base</Button>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Detailed Summary</Typography>
                <Typography variant="subtitle2">This project was done with 3 other individuals as our submission to the HackTheChange 2021 Hackathon.
                The goal of this app was to develop a prototype on a mood tracker mobile application that is catered towards students. Thus, included 
                in the application are student specific features, such as homework deadline management alongside traditional daily emotion tracking.
                This project ultimately won 3rd place in the Hackathon.
                </Typography>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Key Features:</Typography>
                <Grid container rowSpacing={25} columnSpacing={4} className="keyFeatures">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">Tight Deadline</Typography>
                        <Typography variant="subtitle2">A key factor in this project was the very tight deadline of 48 hours, as it was done in a hackathon. Thus, my group and I needed to find an
                        achievable solution which will still yeild us with a presentable project at the end. Ultimately we decided on only creating the front end UI interface of the application, which would
                        effectively show our idea and features we wanted.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={tightDeadline}
                        ></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={login}></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm ={6}>
                        <Typography variant="subtitle3">Login Screen</Typography>
                        <Typography variant="subtitle2">With some extra time at the end of the 48 hours, I decided to provide some real logic and interfacing for the login screen. Thus, the login screen for this application contains
                        some logic to detect if there is a correct password and usernane. It also includes some routing logic to change screens upon an sucessfull login</Typography>
                    </Grid>
                    
                </Grid>
            </div>
            
        </div>

    )
}

export default Zenio