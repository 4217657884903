import { createTheme } from "@mui/material";

const mainTheme = createTheme({
    palette: {
        primary:{
            main: "#FFFFFF"
        }
    },
    typography:{
        heading: {
            fontWeight: "bold",
            color: "#FFFFFF",
            fontSize: 80,
            wdith: '0',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            borderRight: '2px solid rgba(255,255,255,.75)',
            
        },
        subheading: {
            fontWeight: "lighter",
            color: "#FFFFFF",
            fontSize: 50
        },
        subtitle1: {
            fontWeight: "normal",
            color: "#FFFFFF",
            fontSize: 40
        },
        subtitle2: {
            fontWeight: "lighter",
            color: "#FFFFFF",
            fontSize: 20
        },
        subtitle3:{
            color: "#FFFFFF",
            fontSize: 25
        },
        cardtitle1: {
            color: "#FFFFFF",
            fontWeight: "Bold",
            fontSize: 20
        },
        cardDescription: {
            color: "#FFFFFF",
            fontSize: 17
        }
    },
    
   
    
});

export default mainTheme