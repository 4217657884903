import { Typography } from "@mui/material";
import MyAppBar from "../GlobalComponents/AppBar";

function Contact() {

    return (
        <div>
            <MyAppBar />
            <Typography>Contact</Typography>
        </div>

    )
}

export default Contact