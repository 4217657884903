import { Typography, Grid, CardMedia, Button } from "@mui/material";
import MyAppBar from "../GlobalComponents/AppBar";
import PacketLoss from '../images/packetLoss.png'
import packetBuilding from '../images/buildingPackets.png'
import AsyncSeg from '../images/AsyncronousSeg.png'
import './website.css'

function Networking() {

    return (
        <div>
            <MyAppBar />
            <div className="titles">
                <Typography variant="subtitle1">UDP File Transfer Simulation</Typography>
                <Typography variant="subtitle2">Development Time: 4 months</Typography>
                <Typography variant="subtitle2">Technologies: C++ </Typography>
                <Typography variant="subtitle2">Group Size: Individual</Typography>
                <Button target='blank' href="https://github.com/LougheedWan/UDPFileTransfer" variant="outlined">Click Here to See Code Base</Button>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Detailed Summary</Typography>
                <Typography variant="subtitle2">A project simulating a UDP file transfer between a client and server.
                Using C++, I designed a file transfer program through UDP that is able to break down an input file into
                various packets from a host destination, and send those packets in any order to the reciever, which will rebuild the
                packets to form the file once again. This project ultimately uses networking skills and techniques such as packet managment, dealing with
                packet loss, UDP transfer protocol and IP addressing.
                </Typography>
            </div>
            <div className ='summary'>
                <Typography variant="subtitle1">Key Features</Typography>
                <Grid container rowSpacing={25} columnSpacing={4} className="keyFeatures">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">Breaking The File Into Packets</Typography>
                        <Typography variant="subtitle2">A key part of this project was to be able to
                        send any sized file from the host to the reciever. To do this, I needed to write
                        code that would break up the file being transfered into small 1024 byte packets.
                        To do this, I utilized a buffer of 1024 bytes and filled it with file data. I assigned
                        the buffer a segment number and block number (more on this below) and then sent each packet over the UDP
                        protocol. This happens until we detect a file less than 1024 bytes (the final piece of data), in which we terminate
                        after sending it. (See picture)</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={packetBuilding}
                        ></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={AsyncSeg}></CardMedia>
                    </Grid>
                    <Grid item xs={12} sm ={6}>
                        <Typography variant="subtitle3">Asyncronous Segments.</Typography>
                        <Typography variant="subtitle2">Another challenge was dealing with segments of data being 
                        received out of order. As UDP does not guarantee receiving the packets in order (unlike TCP), 
                        I had to program checks to ensure all packet segments are recieved before rebuilding them in order.
                        I first had checks to determine if all segments are recieved (see picture). Then, once all segments are recieved, 
                        I proceeded to rebuild the entire block in order using the segment number given in the data packet. Once the entire block is built, 
                        the program is then ready to recieve the next block of data (with another possible 8 segments)</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle3">Dealing with Packet Loss</Typography>
                        <Typography variant="subtitle2">By far the most challenging part of this project was dealing with 
                        packet loss. As any given time a packet can be lost when transferring between the client and the server. To tackle this
                        each packet is assigned a segment number (1-8), once a segment is received by the reciever, it sends a ACK signal back to the host
                        telling it has recieved the packet that it sent. If the host does not recieve an ACK from the reciever in a given amount of time for a certain
                        packet, the reciever will try to resend the packet up to 3 times. Finally, if by the 3rd time the host still does not receive
                        a ACK. It will terminate the connection and send an error message.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component='img'
                        src={PacketLoss}
                        ></CardMedia>
                    </Grid>
                </Grid>
            </div>
            
        </div>

    )
}

export default Networking