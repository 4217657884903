import './subHeader.css'
import problemSolvingCard from '../images/problemSolvingCard.jpg'
import adaptiveCard from '../images/adaptiveCard.jpg'
import learningCard from '../images/learningCard.jpg'
import webDevCard from '../images/webDevCard.jpg'
import databaseCard from '../images/databaseCard.jpg'
import restCard from '../images/RESTCard.jpg'
import { Typography, Card, CardMedia, CardContent, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


export default function SubHeader() {
    const navigate = useNavigate();
    return (
        <div id='SubHeader'>
            <div className="philosophyDiv">
                <div className='strengthsHeading'>
                    <Typography variant='subtitle1'>My Philosophy</Typography>
                    <Typography variant='subtitle2'>These are the 3 key pillars I believe in, as well as the main attributes in my professional life</Typography>
                </div>
                <div className="cardBox">
                    <Card className='infoCard' >
                        <CardContent>
                            <Typography variant='cardtitle1'>PROBLEM SOLVER</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height="140"
                                image={problemSolvingCard}
                                alt=""

                            />
                            <Typography gutterBottom variant='cardDescription'>I strive to be an effective problem solver, and have garnered
                                much praise on my quick thinking and effective solutions to issues.</Typography>
                        </CardContent>
                    </Card>
                    <Card className='infoCard' >
                        <CardContent>
                            <Typography variant='cardtitle1'>ADAPTIVE</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height="140"
                                image={adaptiveCard}
                                alt=""

                            />
                            <Typography gutterBottom variant='cardDescription'>Being able to adapt to various issues and change
                                your plans accordingly is a vital skill to have. I pride on being able to critically think on the fly and modify my solutions
                                to issues quickly and adequately.</Typography>
                        </CardContent>
                    </Card>
                    <Card className='infoCard' >
                        <CardContent>
                            <Typography variant='cardtitle1'>LEARNER</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height="140"
                                image={learningCard}
                                alt=""

                            />
                            <Typography gutterBottom variant='cardDescription'>I believe learning never stops in life. I like to continuously uphold
                                a humble attitude and a learners mindset wherever I am. You never know what you might learn at any point!</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div className='aboutMeButton'>
                    <Button onClick={() => navigate('/aboutMe')} variant='outlined' size='large'
                        sx={{
                            width: 'auto',
                            fontSize: 'large',
                            float: 'left',
                            marginTop: '3px',
                            fontWeight: 'lesser'
                        }}
                        endIcon={<ArrowForwardIcon />}>More About Me</Button>
                    <Button href='#Expertise' variant='outlined' size='large'
                        sx={{
                            width: 'auto',
                            fontSize: 'large',
                            float: 'left',
                            marginTop: '3px',
                            fontWeight: 'lesser'
                        }}
                        endIcon={<ArrowDownwardIcon />}>Next</Button>
                </div>
            </div>
            <div className="expertiseDiv">
                <div className="projectSubHeader">
                    <Typography variant='subtitle1'>My Expertise</Typography>
                    <Typography variant='subtitle2'>These are my core skills that I have garnered over my past professional employments.
                        However, I am fluent and capable in a vast number of programming languages and tech skills.</Typography>
                </div>
                <div className='cardBox' id='Expertise'>
                    <Card className='infoCard'>
                        <CardContent>
                            <Typography variant='cardtitle1'>WEB DEVELOPMENT</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height="140"
                                image={webDevCard}
                                alt=""

                            />
                            <Typography gutterBottom variant='cardDescription'>From basic Javascript and HTML to advanced frameworks such as Angular and React, I am confident and capable of
                                producing efficent and readable code for any web development project.</Typography>
                        </CardContent>
                    </Card>
                    <Card className='infoCard'>
                        <CardContent>
                            <Typography variant='cardtitle1'>DATABASE MANAGEMENT</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height="140"
                                image={databaseCard}
                                alt=""

                            />
                            <Typography gutterBottom variant='cardDescription'>Fluent in both SQL and NoSQL queries and database structures,
                                I am confident in my skills in both client-server and serverless database technologies, such as SQL Server, MySQL and AWS (DynamoDB)</Typography>
                        </CardContent>
                    </Card>
                    <Card className='infoCard'>
                        <CardContent>
                            <Typography variant='cardtitle1'>RESTFUL WEB SERVICES</Typography>
                            <CardMedia sx={{ marginTop: '10px', marginBottom: '10px' }}
                                component="img"
                                height="140"
                                image={restCard}
                                alt=""

                            />
                            <Typography gutterBottom variant='cardDescription'>Utilizing tools such as Postman, I have garnered a plethora of experience
                                in using the REST framework, writing clean asyncronous web requests without slowing down any application.</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div className='aboutMeButton'>
                    <Button onClick={() => navigate('/projects')} variant='outlined' size='large'
                        sx={{
                            width: 'auto',
                            fontSize: 'large',
                            float: 'left',
                            marginTop: '3px',
                            fontWeight: 'lesser'
                        }}
                        endIcon={<ArrowForwardIcon />}>See More About My Experience and Projects</Button>
                    <Button href='#Title' variant='outlined' size='large'
                        sx={{
                            width: 'auto',
                            fontSize: 'large',
                            float: 'left',
                            marginTop: '3px',
                            fontWeight: 'lesser'
                        }}
                        endIcon={<ArrowUpwardIcon />}>Back To Top</Button>
                </div>
            </div>

        </div>


    )
}
